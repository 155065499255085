var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "page-header",
        {
          attrs: { title: "너무 쉬운\n헤이비글 진행순서", titleSize: "3.4rem" }
        },
        [
          _c("span", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
            _vm._v("안보면 섭외 연락이 와도 진행을 못해요. 지금 정독하세요!")
          ])
        ]
      ),
      _c("div", {
        staticClass: "ui-border-line ui-h-1 ui-mb-3",
        staticStyle: { height: "10px" }
      }),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.$router.push(
              "/artist/my-profile/fee-information-and-agreement/2"
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c("p", { staticClass: "ui-p-indent-2" }, [
        _vm._v("1. 심사 승인이 되면 원하는 의뢰에 지원하세요.")
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c("p", { staticClass: "ui-p-indent-2" }, [
        _vm._v("2. 의뢰인에게 연락이 오면 친절히 응대해주세요."),
        _c("br"),
        _vm._v("* 예약하기를 통한 섭외 확정 요청")
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c("p", { staticClass: "ui-p-indent-2" }, [
        _vm._v("3. 의뢰인이 예약(결제)을 하면 문자로 알려드려요.")
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c("p", { staticClass: "ui-p-indent-2" }, [
        _vm._v("4. 예약이 되면 '채팅하기'로 상세 협의를 진행하세요.")
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c("p", { staticClass: "ui-p-indent-2" }, [
        _vm._v("5. 행사가 종료되면 의뢰인에게 리뷰를 요청하세요."),
        _c("br"),
        _vm._v("리뷰가 많을수록 의뢰 성공률이 5배 이상 높아져요.")
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }