<template>
    <div class="container">
        <page-header :title="`너무 쉬운\n헤이비글 진행순서`" titleSize="3.4rem">
            <span slot="subtitle">안보면 섭외 연락이 와도 진행을 못해요. 지금 정독하세요!</span>
        </page-header>

        <div class="ui-border-line ui-h-1 ui-mb-3" style="height: 10px"></div>

        <div>
            <div class="ui-border-line ui-h-0 ui-mb-2"></div>
            <p class="ui-p-indent-2">1. 심사 승인이 되면 원하는 의뢰에 지원하세요.</p>
            <div class="ui-border-line ui-mt-2"></div>
        </div>

        <div>
            <div class="ui-border-line ui-h-0 ui-mb-2"></div>
            <p class="ui-p-indent-2">2. 의뢰인에게 연락이 오면 친절히 응대해주세요.<br>* 예약하기를 통한 섭외 확정 요청</p>
            <div class="ui-border-line ui-mt-2"></div>
        </div>

        <div>
            <div class="ui-border-line ui-h-0 ui-mb-2"></div>
            <p class="ui-p-indent-2">3. 의뢰인이 예약(결제)을 하면 문자로 알려드려요.</p>
            <div class="ui-border-line ui-mt-2"></div>
        </div>

        <div>
            <div class="ui-border-line ui-h-0 ui-mb-2"></div>
            <p class="ui-p-indent-2">4. 예약이 되면 '채팅하기'로 상세 협의를 진행하세요.</p>
            <div class="ui-border-line ui-mt-2"></div>
        </div>

        <div>
            <div class="ui-border-line ui-h-0 ui-mb-2"></div>
            <p class="ui-p-indent-2">5. 행사가 종료되면 의뢰인에게 리뷰를 요청하세요.<br>리뷰가 많을수록 의뢰 성공률이 5배 이상 높아져요.</p>
            <div class="ui-border-line ui-mt-2"></div>
        </div>

        <footer-box
            submitText="다음"
            :submitCb="() => $router.push('/artist/my-profile/fee-information-and-agreement/2')"
            >
        </footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import CheckBox from '@/components/common/CheckBox'
import Modal from '@/components/common/Modal'

export default {
    components: {
        PageHeader,
        FooterBox,
        CheckBox,
        Modal,
    },
    beforeRouteEnter (to, from, next) {
        if (to?.query?.pageReferer) {
            sessionStorage.setItem('pageReferer:FeeInformationAndAgreement', to.query.pageReferer)
        }
        next()
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 2.6rem !important;
    line-height: 6.6rem !important;
}

input,
textarea {
    padding: 0;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 10rem;
}

textarea {
    line-height: 3rem;
    min-height: 14rem;
    padding: 3rem 0;
}

p {
    color: #000;
    font-size: 1.9rem;
    line-height: 1.5;
}
</style>
